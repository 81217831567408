import * as React from 'react';
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from "@mdx-js/react"
import '../styles/bioModalIPC.scss'


const BioModalIPC = ({ BioTitle, BioThumb, BioText  }) => {

  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return (
      <>
        <Col xs={6} md={3} xl={2} className="text-center mx-xl-2 mx-0 px-1 mb-2" >
          <Row className="justify-content-center m-0 m-md-2 m-xl-0">
            <Col xs={11} md={12} className="text-center speaker-link px-0">
              <Button variant="primary" className="bio-btn" onClick={handleShow}>
                <div className="img__wrap mb-2">
                  <img src={`https://web-cdn.meridianuniversity.edu/ipc-2021-headshots/${BioThumb}`} alt={`${BioTitle} thumbnail`} className="img-fluid"></img>
                </div>
                <h5 className="speaker-name">{ BioTitle }</h5>
              </Button>
            </Col>
          </Row>
        </Col>

        <Modal show={show} size="lg" onHide={handleClose} centered>
          <Modal.Header className="ps-0 pb-0 mb-3" closeButton>
            <Modal.Title className="mt-5 ms-5">{ BioTitle }</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-5 pt-3">
            <Row>
              <Col md={4} className="text-center">
                <img src={`https://web-cdn.meridianuniversity.edu/ipc-2021-headshots/${BioThumb}`} alt={`${BioTitle} thumbnail`} className="img-fluid"></img>
              </Col>
              <Col md={8}>
                <MDXProvider>
                  <MDXRenderer>{ BioText }</MDXRenderer>
                </MDXProvider>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </>
    );
}

export default BioModalIPC
