import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { handleSubmit as formSubmitter } from "../../../libs/form-submitter";
import Cookies from "js-cookie";

import '../styles/contactForm.scss';
import '../styles/spinner.scss';

function IpcContactForm2022({ submitCampaignID, submitType }) {

  /* Get GAUID */
  const clientId = typeof document !== "undefined" ? Cookies.get('_ga') : null;
  const gaUID = ((clientId != null) || (clientId != undefined)) ? clientId.substring(6) : "No GAUID Detected";
  
  /* Get URL Params */
  const urlRef = typeof window !== 'undefined' ? window.location.search : '';
  const url = urlRef.slice(1);
  const urlParams = new URLSearchParams(url);
  const utm_source = urlParams.has("utm_source") ? urlParams.get("utm_source") : 'NoSource';
  const utm_medium = urlParams.has("utm_medium") ? urlParams.get("utm_medium") : 'NoMedium';
  const utm_campaign = urlParams.has("utm_campaign") ? urlParams.get("utm_campaign") : 'NoCampaign';
  const utm_term = urlParams.has("utm_term") ? urlParams.get("utm_term") : 'NoTerm';
  const utm_content = urlParams.has("utm_content") ? urlParams.get("utm_content") : "NoContent";
  const utm_gclid = urlParams.has("gclid") ? urlParams.get("gclid") : "NoGCLID";

  /* Get Initial Cookie Value */
  let muSource = Cookies.get('_muSource'); 
  let muMedium = Cookies.get('_muMedium');
  let muCampaign = Cookies.get('_muCampaign'); 
  let muTerm = Cookies.get('_muTerm');
  let muContent = Cookies.get('_muContent');
  
  /* Set Cookie Value and Store for Logic App Values */
  let source;
  let medium;
  let campaign;
  let term;
  let content;
  source = ( muSource === undefined ? ( Cookies.set('_muSource', utm_source, { expires: 7 }), source = Cookies.get('_muSource') ) : ( muSource === "NoSource" && utm_source !== "NoSource" ? ( Cookies.set('_muSource', utm_source, { expires: 7 } ), source = Cookies.get('_muSource') ) : ( muSource !== "NoSource" && utm_source !== "NoSource" ? ( Cookies.set('_muSource', utm_source, { expires: 7 } ), source = Cookies.get('_muSource') ) : ( source = muSource ) ) ) );
  medium = ( muMedium === undefined ? ( Cookies.set('_muMedium', utm_medium, { expires: 7 }), medium = Cookies.get('_muMedium') ) : ( muMedium === "NoMedium" && utm_medium !== "NoMedium" ? ( Cookies.set('_muMedium', utm_medium, { expires: 7 } ), medium = Cookies.get('_muMedium') ) : ( muMedium !== "NoMedium" && utm_medium !== "NoMedium" ? ( Cookies.set('_muMedium', utm_medium, { expires: 7 } ), medium = Cookies.get('_muMedium') ) : ( medium = muMedium ) ) ) );
  campaign = ( muCampaign === undefined ? ( Cookies.set('_muCampaign', utm_campaign, { expires: 7 }), campaign = Cookies.get('_muCampaign') ) : ( muCampaign === "NoCampaign" && utm_campaign !== "NoCampaign" ? ( Cookies.set('_muCampaign', utm_campaign, { expires: 7 } ), campaign = Cookies.get('_muCampaign') ) : ( muCampaign !== "NoCampaign" && utm_campaign !== "NoCampaign" ? ( Cookies.set('_muCampaign', utm_campaign, { expires: 7 } ), campaign = Cookies.get('_muCampaign') ) : ( campaign = muCampaign ) ) ) );
  term = ( muTerm === undefined ? ( Cookies.set('_muTerm', utm_term, { expires: 7 }), term = Cookies.get('_muTerm') ) : ( muTerm === "NoTerm" && utm_term !== "NoTerm" ? ( Cookies.set('_muTerm', utm_term, { expires: 7 } ), term = Cookies.get('_muTerm') ) : ( muTerm !== "NoTerm" && utm_term !== "NoTerm" ? ( Cookies.set('_muTerm', utm_term, { expires: 7 } ), term = Cookies.get('_muTerm') ) : ( term = muTerm ) ) ) );
  content = ( muContent === undefined ? ( Cookies.set('_muContent', utm_content, { expires: 7 }), content = Cookies.get('_muContent') ) : ( muContent === "NoContent" && utm_content !== "NoContent" ? ( Cookies.set('_muContent', utm_content, { expires: 7 } ), content = Cookies.get('_muContent') ) : ( muContent !== "NoContent" && utm_content !== "NoContent" ? ( Cookies.set('_muContent', utm_content, { expires: 7 } ), content = Cookies.get('_muContent') ) : ( content = muContent ) ) ) );

  const formik = useFormik({
    initialValues: {
      submitFirstName: "",
      submitLastName: "",
      submitEmail: "",
      submitCampaignID,
      submitType,
      submitUTMSource: source,
      submitUTMMedium: medium,
      submitUTMCampaign: campaign,
      submitUTMTerm: term,
      submitUTMContent: content,
      submitGCLID: utm_gclid,
      submitUUID: gaUID,
      submitForm: "IPC Contact Form 2022",
    },

    validationSchema: Yup.object({
      submitFirstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      submitLastName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Required"),
      submitEmail: Yup.string()
        .email("Invalid email address")
        .required("Required"),
      submitGCLID: Yup.string(),
      submitUUID: Yup.string(),
    }),
    onSubmit: () => formSubmitter(formValues, submitConfig),
  });

  const submitConfig = {
    preferThankYou: true,
    thankYouType: "free-event",
    thankYouForm: "ipc-2022",
    displayText:
      "Thank+you+for+registering+for+the+2022+Integral+Practitioner+Convergence.+We%27ll+reach+out+with+more+information+soon.",
    backButtonAction: "javascript%3Ahistory.go%28-1%29",
  };

  const formValues = formik.values;

  const [isActive, setActive] = useState("false");
    const ToggleClass = () => {
        setActive(!isActive); 
    };

  return (
    <div>
      <div id="submitting" className={isActive ? null : "show" }>
          <Row className="justify-content-center align-items-center">
              <Col xs={12} className="p-3 p-lg-4 text-center">
                  <h2 className="submitting-title">Submitting <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="2x" /></h2>
              </Col>
          </Row>
      </div>
    <div id="bottomContactForm">
      <Container fluid="lg">
        <Row className="align-items-center justify-content-center">
          <Col xl={5} lg={6} md={7} className="contact-form text-center">
            <h1 className="mb-3">Register for the 2022 Convergence</h1>
            <p><strong>Participation in this year's online Integral Practitioner Convergence is free of charge.</strong></p>
            <p>
              Register for free access to three days of panels and collaboration
              addressing the future of coaching, organizational development,
              leadership, and integral practice across domains. Join 100+
              panelists from around the globe on May 6, 7, and 8, 2022 starting
              at 8am PDT each day. Session recordings will be available for one
              week after the Convergence for registrants. Advanced registration
              only - sign up below to attend!
            </p>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group controlId="namesGroup" className="names">
                <Form.Group className="first">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    id="submitFirstName"
                    name="submitFirstName"
                    type="text"
                    placeholder="First Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.submitFirstName}
                  />
                  {formik.touched.submitFirstName &&
                  formik.errors.submitFirstName ? (
                    <div>{formik.errors.submitFirstName}</div>
                  ) : null}
                </Form.Group>
                <Form.Group className="last">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    id="submitLastName"
                    name="submitLastName"
                    type="text"
                    placeholder="Last Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.submitLastName}
                  />
                  {formik.touched.submitLastName &&
                  formik.errors.submitLastName ? (
                    <div>{formik.errors.submitLastName}</div>
                  ) : null}
                </Form.Group>
              </Form.Group>
              <Form.Group className="email mb-4">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  id="submitEmail"
                  name="submitEmail"
                  type="email"
                  placeholder="Email Address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.submitEmail}
                />
                {formik.touched.submitEmail && formik.errors.submitEmail ? (
                  <div>{formik.errors.submitEmail}</div>
                ) : null}
              </Form.Group>
              <Form.Group>
                <Form.Control
                  id="submitGCLID"
                  name="submitGCLID"
                  type="hidden"
                  value
                />
              </Form.Group>
              <Form.Group>
                <Form.Control
                  id="submitUUID"
                  name="submitUUID"
                  type="hidden"
                  value=""
                />
              </Form.Group>
              <Button variant="primary" type="submit" onClick={ToggleClass} disabled={!(formik.isValid && formik.dirty)}>
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
    </div>
  );
}

export default IpcContactForm2022;
